var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.tbData,"single-expand":_vm.singleExpand,"search":_vm.search,"loading":_vm.loadingTb,"loading-text":"Sedang Memuat Data...","expanded":_vm.expanded,"item-key":"id"},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.noRow",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.tbData.map(function(x) {return x.projectName; }).indexOf(item.projectName)+1)+" ")]}},{key:"item.totalPaket",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.G_numFormat(item.totalPaket))+" ")]}},{key:"item.totalPaguDipa",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.G_numFormat(item.totalPaguDipa))+" ")]}},{key:"item.totalPaketMapping",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.G_numFormat(item.totalPaketMapping))+" ")]}},{key:"item.totalDipaMapping",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.G_numFormat(item.totalDipaMapping))+" ")]}},{key:"item.totalPaketNotMapping",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.G_numFormat(item.totalPaketNotMapping))+" ")]}},{key:"item.totalDipaNotMapping",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.G_numFormat(item.totalDipaNotMapping))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }