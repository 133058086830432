<template>
    <div>
        <v-data-table :headers="headers" :items="tbData" :single-expand="singleExpand" :search="search"
            :loading="loadingTb" loading-text="Sedang Memuat Data..." :expanded.sync="expanded" item-key="id">

             <template v-slot:item.noRow="{ item }" >
                 {{tbData.map(function(x) {return x.projectName; }).indexOf(item.projectName)+1}}
             </template>
             <template v-slot:item.totalPaket="{ item }">
                {{G_numFormat(item.totalPaket)}}
            </template>
            <template v-slot:item.totalPaguDipa="{ item }">
                {{G_numFormat(item.totalPaguDipa)}}
            </template>
            <template v-slot:item.totalPaketMapping="{ item }">
                {{G_numFormat(item.totalPaketMapping)}}
            </template>
            <template v-slot:item.totalDipaMapping="{ item }">
                {{G_numFormat(item.totalDipaMapping)}}
            </template>
            <template v-slot:item.totalPaketNotMapping="{ item }">
                {{G_numFormat(item.totalPaketNotMapping)}}
            </template>
            <template v-slot:item.totalDipaNotMapping="{ item }">
                {{G_numFormat(item.totalDipaNotMapping)}}
            </template>

        </v-data-table>

      

    </div>
</template>
<script>
    export default {
        data() {
            return {
                expanded: [],
                singleExpand: false,
                  headers: [
                    { text: 'No', value: 'noRow',sortable: false,width: "1%" },
                    { text: 'Proyek', value: 'projectName' },
                    { text: 'Total Paket', value: 'totalPaket',align:'right' },
                    { text: 'Total Pagu Dipa', value: 'totalPaguDipa',align:'right' },
                    { text: 'Total Paket yang Sudah di Mapping', value: 'totalPaketMapping',align:'right' },
                    { text: 'Total Pagu Dipa yang Sudah di mapping', value: 'totalDipaMapping',align:'right' },
                    { text: 'Total Paket yang belum di mapping', value: 'totalPaketNotMapping',align:'right' },
                    { text: 'Total Pagu Dipa Yang belum di mapping', value: 'totalDipaNotMapping',align:'right' },

                    
                ],
            }
        },
        mounted(){
           
        },
        methods: {
            getTable(){
                this.$emit('getTable');
            },
        },

        props: ['tbData', 'search', 'loadingTb'],
    }
</script>