import LayoutAdmin from '@/layouts/LayoutAdmin'
import myTable from './table'

export default {
  data() {
    return {
      search: '',
      loadingTb: false,
      tbData: [],
    }
  },
  created() {
    this.$emit(`update:layout`, LayoutAdmin);
  },
  mounted() {
    this.getTable()   
  },
  components: {
    myTable
  },
  methods: {
    getTable() {
      this.isShow = true
      this.loadingTb = true
      
      this.G_GetAny('proyek/monitoring').then(res => {
        console.log(res.data);
        this.tbData = res.data

        this.loadingTb = false
       
      })
     

    },
    
  
  }
}